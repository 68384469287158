import React, { useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import PdfButton from "../components/PdfButton"
import { Link } from "gatsby"
import {
  certificationEfaTest,
  requirementsEfa,
  efaDocuments,
} from "../data/certification-efp"
import Iva from "../components/Iva"
import parse from "react-html-parser"
import AnnouncementEfp from "../components/Announcement/AnnouncementEfp"
import TitlePage from "../components/TitlePage"
import { useAxiosClient } from "../service/useAxiosClient"
import { useEffect } from "react"
import TutorialBtn from "../components/TutorialBtn"

export default function CertificationTestEFA({ location }) {
  const id = 3
  const [test, setTest] = useState()
  const [price, setPrice] = useState()

  const [{ data: dataTest }] = useAxiosClient({
    url: `/exams/dates/web/next-calls/?exam_type=EFA`,
    method: "GET",
  })

  useEffect(() => {
    if (dataTest && dataTest.EFA) {
      const [test] = dataTest.EFA.calls
      setTest(test)
    }
  }, [dataTest])

  const [{ data: dataPrice }] = useAxiosClient({
    url: `/professional-certificates/web/exam-price/?professional_certificate=${id}`,
    method: "GET",
  })

  useEffect(() => {
    if (dataPrice) {
      setPrice(dataPrice)
    }
  }, [dataPrice])

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificacion" },
    {
      label:
        "Exámenes certificación EFPA <em>European Financial Advisor</em> (EFA), examen completo EFA",
    },
  ]
  const renderContent = () => {
    return (
      certificationEfaTest &&
      certificationEfaTest.map(item => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-content">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }
  const renderContentRight = () => {
    return (
      requirementsEfa &&
      requirementsEfa.map(item => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-container">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }
  const renderDocuments = () => {
    return (
      efaDocuments &&
      efaDocuments.map(item => {
        return (
          <PdfButton
            isExternal
            className="mb-2"
            text={item.name}
            link={item.link}
          />
        )
      })
    )
  }
  return (
    <Layout>
      <TitlePage
        title="Exámenes certificación EFPA European Financial Advisor (EFA)"
        url={location.pathname}
      />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Exámenes certificación EFPA <em>European Financial Advisor</em> (EFA)"
          icon="fas fa-user-tie"
        />
        <span className="Certification-text">
          El examen EFA - <em>European Financial Advisor</em>, puede realizarlo
          en un único examen o en 2 exámenes parciales.
        </span>
        <div className="CertificationTestEFP-actions">
          <Link
            className="CertificationTestEFP-link selected"
            to="/examenes-certificacion-efa"
          >
            Examen completo EFA
          </Link>
          <Link
            className="CertificationTestEFP-link"
            to="/examenes-certificacion-efa-eip"
          >
            Examen parcial Nivel I (EIP)
          </Link>
          <Link
            className="CertificationTestEFP-link"
            to="/examenes-certificacion-efa-nivel-II"
          >
            Examen parcial Nivel II
          </Link>
        </div>
        <TitleBlock
          className="Certification-title-block"
          title="Examen completo EFA"
          icon="d-none"
        />
        <div className="CertificationTestEFP">
          <div className="row efpa-row">
            <div className="col-12 col-md-6 Certification-padding-r">
              <div className="CertificationTestEfa-content">
                {renderContent()}
                <TutorialBtn />
                <div className="Certification-content efpa-content">
                  <TitleBlock
                    className="Certification-title-block"
                    title="Simulación examen"
                    icon="fas fa-file-alt"
                  />
                  <PdfButton
                    isExternal
                    className="mb-2"
                    text="Simulación parte teórica"
                    link="/docs/simulacion_examen_efa_completo.pdf"
                  />
                  <PdfButton
                    className="mb-2"
                    text="Examen online - simulación parte práctica"
                    link="/docs/simulacion-Parte-Practica-EFA-examen-online-ok.pdf"
                    isExternal
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 Certification-padding-l">
              {renderContentRight()}
              <div className="Certification-content efpa-content">
                <TitleBlock
                  className="Certification-title-block"
                  title="Documentación"
                  icon="fas fa-file-alt"
                />
                {renderDocuments()}
              </div>
              {test && (
                <div className="Certification-content">
                  <AnnouncementEfp
                    title={test?.call_text}
                    date={test?.exams_date_text}
                    link={test?.inscription_link}
                  />
                </div>
              )}
              <div className="efpa-content">
                <TitleBlock
                  className="Certification-title-block"
                  title="Coste"
                />
                <Iva
                  price={`${price} € (IVA incluido)`}
                  text="Consultar en EFPA los descuentos para entidades colaboradoras."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
